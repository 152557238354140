import React, { useEffect, useState } from "react"
import "../styles/root.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import NavWrapper from "../components/nav/nav-wrapper"
import ImageFilter from "../components/photography/ImageFilter"
import stylesnav from "../components/nav/nav.module.scss"
import Wrapper from "../components/wrapper"

function Contact() {
  return (
    <div>
      <NavWrapper title={"Skills"}>
      </NavWrapper>
      <Wrapper>
        <div className={"display-1"}>Skills</div>
      </Wrapper>
    </div>
  )
}
export default Contact